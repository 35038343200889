.location-modal-body {
  max-height: 400px;
  overflow: scroll;
}
.reviewModal {
  margin-top: 9rem;
}
.see-more-btn {
  color: #000000;
  cursor: pointer;
}
