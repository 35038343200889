.MuiDrawer-paperAnchorLeft {
  left: 0;
  right: auto;
  background: #222a25 !important;
  font-weight: bolder;
  color: #fff !important;
  max-width: 250px;
  width: 300px;
  transition: ease-in-out 0.3s;
}
h3,
h4,
.maindivuser h2 {
  font-family: fantasy !important;
}
/* .Main_content{
  margin-left: 250px;
} */

.logo {
  padding: 15px;
  background: rgb(85 87 86);
  font-weight: bolder;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.bashboard ul {
  text-decoration: none;
  list-style: none;
  padding: 0;
}
ul {
  text-decoration: none;
  list-style: none;
  padding: 0;
}
a {
  text-decoration: none;
  color: #fff;
}

.bashboard a {
  color: #fff !important;
  font-weight: bolder;
}
.bashboard li {
  width: 100%;
  padding: 14px 30px;
  border-bottom: 1px solid;
  font-weight: 500;
  font-size: 18px;
}

i.fas.fa-arrow-down {
  margin-left: 60px;
}

li:hover {
  background: rgb(23 48 114);
  text-decoration: none;
}
.hoveron {
  background: rgb(23 48 114);
  text-decoration: none;
  border-top: 1px solid;
}

li:hover a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.dropbox {
  margin-left: 35px;
  display: none;
}
.dropbox view {
  display: block;
  transition: ease-in-out 1s;
}
.fa-angle-down:before {
  content: "\f107";
  margin-left: 60px;
  cursor: pointer;
}

button.bttn.btn-danger {
  background: #f11515;
  border: 1px solid red;
  border-radius: 11px;
  color: #fff;
  padding: 6px;
  font-size: 11px;
  cursor: pointer;
}
button.bttn.btn-danger:hover {
  background: #780a0a;
  border: 1px solid #780a0a;
}
.btn-danger:focus {
  box-shadow: none !important;
}
button.bttn.btnupdate {
  border: 1px solid red;
  border-radius: 11px;
  color: #fff;
  padding: 6px;
  font-size: 11px;
  cursor: pointer;
}
button.bttn.btnupdate:hover {
  background: #780a0a;
  border: 1px solid #780a0a;
}
button:focus {
  outline: 1px dotted;
  outline: none;
}
button.bttn.btnupdate {
  background: darkgoldenrod;
  border: 1px solid darkgoldenrod;
  border-radius: 12px;
  margin-left: 13px;
  color: #fff;
  cursor: pointer;
}

button.bttn.btnupdate:hover {
  background: #684c06;
  border: 1px solid #684c06;
  border-radius: 12px;
  margin-left: 13px;
  color: #fff;
  cursor: pointer;
}
.users_table {
  padding: 20px 55px;
}
.top_bar {
  padding: 12px 30px;
  color: #fff;
  background: #222a25;
  font-weight: bolder;
}
.top_bar {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.logoutt-btn {
  background: rgb(217 215 215 / 34%);
  color: #fff;
  cursor: pointer;
  border: 0px;
  margin-left: 16px;
  /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
}
.logoutt-btn:hover {
  background: #545454;
}

.announcement-btn {
  background: rgb(39 159 255 / 49%);
  border-radius: 5px;
  padding: 5px 12px;
  border: 0px;
  color: #fff;
  cursor: pointer;
}
.announcement-btn:hover {
  background: #545454;
}
.listingupdate.adjust {
  min-width: 400px;
  padding: 16px 7px;
}

.dropdown {
  position: absolute;
  right: 15px;
  /* padding: 10px; */
  background: #fff;
  border: 1px solid #000;
  color: darkslategrey;
  display: none;
}
.dropdown a {
  color: #3f3a61;
  padding: 26px 22px;
}
.dropdown span:hover {
  color: #fff;
}
.dropdown span {
  padding: 0px 25px;
  color: #336e6e;
  cursor: pointer;
}
.top_bar {
  position: relative;
}
.titlee {
  text-align: center;
  padding: 15px 12px;
}
.dropdown li {
  text-align: start;
  margin: 7px 0px;
}
button.bttn.btn-success {
  border-color: #3f3a61;
  border: 1px solid #3f3a61;
  border-radius: 11px;
  padding: 5px 7px;
  cursor: pointer;
  background-color: #3f3a61 !important;
}
.lelel {
  width: 100%;
  height: 100%;
  padding: 9px 43px 50px;
}
/* .MuiDialog-paperWidthSm {
    width: 800px !important;
} */
.lelel h2 {
  margin-bottom: 45px;
}
.lelel label {
  font-weight: 700;
}
.MuiIconButton-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: end !important;
}
.css-1bn53lx {
  width: 100% !important;
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.overfflo.css-1yhpg23-MuiTableCell-root {
  max-width: 343px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reportview {
  display: block;
  justify-content: space-between;
  margin-bottom: 12px;
}
.reportview h6 {
  margin-right: 50px;
  font-weight: 700;
}
.flexxview {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.profile img {
  width: 100%;
}
.profile {
  width: 40%;
  margin-right: 15px;
}
.detaill {
  width: 60%;
}

.reportview p {
  overflow: auto;
  text-overflow: ellipsis;
  width: 250px;
}

.active-hours {
  font-weight: 600;
}
.css-169iwlq-MuiCalendarPicker-root {
  max-height: 170px !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.card_div {
  display: flex;
  justify-content: center;
}
.cardds hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgb(255 255 255);
}
.cardds {
  /* padding: 33px; */
  padding-top: 33px;
  padding-left: 33px;
  padding-right: 33px;
  padding-bottom: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  color: #fff;
}
.cardds .title {
  padding: 25px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_bar {
  padding: 15px;
}
.cardds.one {
  background-image: linear-gradient(to right, #2b3d34, #676767);
}
.cardds.two {
  background-image: linear-gradient(to right, #254036, #254036);
}
.cardds.three {
  background-image: linear-gradient(to right, #222a25, #222a25);
}
.title i {
  font-size: 25px;
  border: 1px solid;
  padding: 8px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
}
span.MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary.css-18lrjg1-MuiCircularProgress-root {
  position: absolute;
  right: 40%;
  top: 50%;
}

.MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary.circulerr.css-18lrjg1-MuiCircularProgress-root {
  position: initial;
  width: 20px !important;
  color: #fff !important;
  height: 20px !important;
}

.boot-loader {
  position: initial;
  width: 20px !important;
  color: #fff !important;
  height: 20px !important;
}
.boot-loader2 {
  position: initial;
  width: 15px !important;
  color: #fff !important;
  height: 15px !important;
  border: 0.1em solid currentColor !important;
  border-right-color: transparent !important;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.2em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary.circulerr.css-z01bqi-MuiCircularProgress-root {
  position: initial;
  width: 20px !important;
  color: #fff !important;
  height: 20px !important;
}

.users_table .loaderr {
  position: absolute;
  right: 44%;
  top: 58%;
  /* background: #0000005e; */
  border-radius: 50%;
}
.loaderr img {
  width: 100px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.viewws.css-i4bv87-MuiSvgIcon-root {
  color: #000;
  font-size: 32px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.tableview.css-i4bv87-MuiSvgIcon-root {
  margin-left: 25px;
  font-size: 24px;
  cursor: pointer;
}
button.viewsss {
  background: transparent;
  border: 0px;
}
.lgoo img {
  width: 60%;
  margin-right: 15px;
}

a.lgoo {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.MuiIconButton-root:hover {
  background-color: transparent !important;
}
.useruPDATE {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}
.maindivuser {
  padding: 12px;
}
img.profille {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.listingupdate {
  padding: 11px;
  width: 100%;
}
.listingupdate label {
  font-weight: 700;
}

.listingupdate input {
  margin-left: 20px;
}
/* .listingupdate img {
  width: 75px;
} */
.imgflex {
  display: flex;
  padding: 15px 0px;
}
.flexxiidays {
  display: flex;
  flex-wrap: wrap;
}

.bioflex {
  width: 50%;
}

span.inerflexi {
  margin-left: 16px;
}
h6.inerflexi {
  text-transform: capitalize;
}
span.active-hours {
  font-weight: 500;
}
.devideiid {
  width: 150px;
}
label.spaace {
  margin-right: 20px;
}
.excle_btn {
  text-align: right;
  padding: 8px;
}
.search_view input[type="search"] {
  outline: none;
  border: 2px solid #3f3a61;
  border-radius: 5px;
  padding: 5px 30px;
  width: 100%;
}

.search_view {
  padding: 10px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.excle_innbtn {
  border: 2px solid #3f3a61;
  margin-right: 10px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background: #173072;
  color: white;
  font-weight: 700;
  margin-left: 10px;
}
.btnn {
  text-align: right;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.filterr.css-i4bv87-MuiSvgIcon-root {
  cursor: pointer;
}
.statuss {
  display: flex;
}
tr.MuiTableRow-root.css-34nofg-MuiTableRow-root:hover {
  background: #6983887a;
}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.filterr.css-i4bv87-MuiSvgIcon-root {
  margin-left: 6px;
}
.flex_images img {
  width: 25%;
  margin: 5px;
  cursor: pointer;
}
put.MuiSelect-nativeInput.css-1k3x8v3 {
  width: 100%;
}

.MuiFormControl-root.MuiTextField-root.css-i44wyl {
  display: block;
  /* width: 100% !important; */
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1v4ccyo {
  width: 100% !important;
}
.reportmainview {
  display: flex;
  justify-content: space-between;
}

.reportimg {
  width: 35%;
}

.reportcontent {
  width: 60%;
}
img.rpimg {
  width: 100%;
}
.absol {
  position: absolute;
  top: -16px;
  right: -5px;
  cursor: pointer;
  z-index: 999;
}
label.spaace {
  position: relative;
}

label.spaace {
  position: relative !important;
}

.rell {
  position: relative;
}

.absol
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #923030;
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.avail_green.css-1yhpg23-MuiTableCell-root {
  background: darkgreen;
  color: white;
}

span.avail_green {
  background: green;
  color: #fff;
  padding: 6px;
  border-radius: 5px;
  font-size: 12px;
}

span.out_red {
  background: #910023;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}
.indv {
  padding: 6px !important;
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root {
  width: 9.1vw !important;
}
.css-1brzq0m {
  overflow-x: hidden;
  width: 320px;
  max-height: 170px !important;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}
.deactive {
  pointer-events: none;
  opacity: 0.6;
}
.phoonee1 input.form-control {
  height: 53px !important;
  width: 100% !important;
  background: #fff !important;
  border: 1px solid #c4c4c4 !important;
}
.react-tel-input .flag-dropdown.invalid-number {
  border-color: #c4c4c4 !important;
}
.phoonee input.form-control {
  /* height: 53px !important; */
  width: 100% !important;
}
input.form-control {
  height: 53px !important;
}
.main1 {
  padding: 0px;
  width: 33%;
  margin: 10px;
  /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
  border-radius: 10px;
  color: #fff;
}
.cardds {
  height: 60%;
}
.attachment img {
  width: 25%;
  margin: 0px 12px;
}
.attachment {
  display: flex;
  align-items: center;
}
div#chart {
  padding-top: 33px;
  height: 268px;
}
.newacc {
  color: aliceblue !important;
}

.newacc a {
  color: bisque;
}
/* .card.signup {
  height: 500px;
} */
.btnn.adduser span.MuiIconButton-label {
  color: aliceblue;
}
/* .phone input.form-control {
  width: 100% !important;
} */

.category_flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.innerflex {
  width: 25% !important;
  /* max-width: 33% ; */
  padding: 6px;
  border: 1px solid #fff;
  position: relative;
}

.cat_img img {
  width: 100%;
  max-height: 250px;
  min-height: 250px;
}

.category_main {
  padding: 0;
  margin: 0;
}
.users_table.category {
  background: #fff;
}
.content_flex {
  display: flex;
  justify-content: space-between;
  padding: 5px 6px;
  color: #060505;
  /* background-image: linear-gradient(to right, #2b3d34, #a89d9dd9); */
  transition: 0.5s ease;
  align-items: center;
  border: 1px solid;
  border-radius: 7px;
}
.status_btn {
  width: 15px;
  height: 15px;
  background: #2e7d32;
  border-radius: 23px;
  margin-right: 5px;
  border: 0px;
}
.status_btn_danger {
  background: #bc0000b8;
  width: 15px;
  height: 15px;
  border-radius: 23px;
  border: 0px;
}
.content_flex:hover {
  background: #173072;
  color: #fff;
  transition: 0.5s ease;
}
.cat_content {
  margin-top: 5px;
}
.inner_content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.minuss.css-i4bv87-MuiSvgIcon-root {
  color: #b82e2e;
  cursor: pointer;
  font-size: 35px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.updating.css-i4bv87-MuiSvgIcon-root {
  font-size: 35px;
  cursor: pointer;
}
button.btn.float-right.login_btn.cat {
  width: 100% !important;
}
.newone {
  background: #7a96a3;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}
.imgflex img {
  max-width: 170px !important;
}
.content_flex h5 {
  font-size: 16px;
  margin: 0;
}
.minuss.green {
  color: #2e7d32 !important;
}
.cat_img {
  opacity: 1;
}
.innerflex:hover .cat_img {
  opacity: 0.2;
}
.overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}
.excle_btn.listing {
  display: flex;
}
.innerflex:hover .overlay {
  opacity: 1;
}
div#outlined-select-currency {
  background: #fff;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-uhb5lp {
  width: 70% !important;
  max-width: 100%;
}

.MuiFormControl-root.MuiTextField-root.flo.css-i44wyl {
  width: 100%;
}
.inner
  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  position: absolute;
  top: 0;
  left: 0;
  margin: 6px;
  z-index: 555;
  color: #fff;
}
tr.MuiTableRow-root.css-34nofg-MuiTableRow-root:hover {
  background: #edf2f37a;
  box-shadow: rgb(162 162 201 / 5%) 0px 50px 100px -20px,
    rgb(99 93 93 / 37%) 0px 30px 60px -30px,
    rgb(0 0 0 / 40%) 0px -2px 6px 0px inset;
}
.inner
  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.css-1ujsas3 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 555;
  margin: 5px;
  color: #fff;
}
li.MuiMenuItem-root {
  display: flex !important;
  border-bottom: 1px solid #9387878f !important;
  padding: 10px !important;
}
svg.MuiCircularProgress-svg.css-1idz92c-MuiCircularProgress-svg {
  width: 20px !important;
  color: #fff !important;
  height: 20px !important;
}
td.MuiTableCell-root {
  width: 200px !important;
  text-overflow: ellipsis !important;
  max-width: 230px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
.Main_content.mrggg {
  /* margin-left: 260px; */
}
@media only screen and (max-width: 1400px) {
  .users_table {
    padding: 20px 10px;
  }
}
@media only screen and (max-width: 600px) {
  .MuiDrawer-paperAnchorLeft {
    max-width: 0px;
    width: 30px;
    transition: ease-in-out 0.3s;
  }
  .Main_content {
    margin-left: 0;
  }
  .users_table {
    /* padding: 6px 14px; */
    padding: 0px 0px;
  }
}

/* ------------------------------------- */

.top_bar {
  background: #0f3650 !important;
}

button.logoutt-btn {
  background: rgb(39 159 255 / 49%);
  border-radius: 5px;
  padding: 5px 8px;
}

.logo {
  background: #103c58 !important;
}

.MuiDrawer-paperAnchorLeft {
  background: #0c354f !important;
}

li:hover {
  background: #246d9c !important;
}

.cardds.two {
  background-image: linear-gradient(to right, #0f3650, #3f87b7);
}

.cardds.three {
  background-image: linear-gradient(to right, #6ac6de, #0d4883);
}

.cardds.one {
  background-image: linear-gradient(to right, #1c78b2, #24a8d0);
}
.hoveron {
  background: #246d9c !important;
  font-weight: 500;
  font-size: 18px;
}

.login_logo img {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(49 75 124 / 60%);
  padding: 12px;
  border-radius: 12px;
}
.card {
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  /* background-color: #2C4F39 !important; */
  box-sizing: border-box;
  background: rgb(2 8 22 / 60%);
}
ul.tags {
  display: flex;
  /* justify-content: revert; */
  overflow: scroll;
  flex-wrap: wrap;
}

li.tag {
  margin: 8px 4px;
  display: flex;
  border: 1px solid #000;
  border-radius: 25px;
  padding: 12px;
  align-items: center;
}

span.tag-title {
  font-size: 12px;
}
.listingupdate .mt-5 {
  margin-top: 0px !important;
}

button.btn.btnTags {
  color: #fff;
}

li.tag:hover {
  color: #fff;
}

input.autocomplete_input {
  border: 0px;
  width: 90%;
  padding: 16px 14px;
}

.listingupdate .profil_locat.w-68 {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 0px;
  width: 100% !important;
}

input.autocomplete_input:focus-visible {
  outline: none;
}
.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 9px;
  border: 1px solid #c4c4c4;
}
.backTag .css-1jruifh-MuiStack-root {
  width: 80% !important;
}
.makeStyles-root-1 {
  width: 100%;
  cursor: pointer;
  max-width: 49ch !important;
  background-color: #e4e5e5 !important;
}
li:hover {
  background: #246d9c !important;
  color: #fff;
}
ul.tags.displynone {
  display: none;
}
p.error_location.w-48 {
  color: red;
}
.h2,
h2 {
  font-family: fantasy !important;
}
.circulrs svg.MuiCircularProgress-svg.css-1idz92c-MuiCircularProgress-svg {
  color: #312f2c !important;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.circulrs {
  text-align: right;
  padding: 10px 25px;
}

div#standard-select-currency\ genderr {
  color: #000 !important;
  background: #ffff;
  min-width: 313px !important;
}

.css-yf8vq0-MuiSelect-nativeInput {
  color: #fff;
}
button.bttn.btn-success,
.colr {
  box-shadow: none;
  border: none;
}
button.bttn.btn-success:active,
button.bttn.btn-success:focus {
  box-shadow: none;
  border: none;
}
.useruPDATE.ServiceUpdatee {
  max-width: 1000px;
  display: block;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
}

.maindivuser.cusstomesr {
  background: #f8f9fb;
}

.headingDiv.customme {
  background: #f8f9fb;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 24px;
}

.headingDiv.customme h1.headingSection {
  font-size: 16px;
  font-weight: 500;
}

.divDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h1.detailH1 {
  font-size: 16px;
  font-weight: 700;
}
button.bttn.btn.btnupdate.bttn.btn-danger {
  background: #b11010;
  border: 1px solid #b11010;
  border-radius: 11px;
  color: #fff;
  padding: 6px;
  font-size: 11px;
  cursor: pointer;
}

.deletediv img {
  width: 20px;
  cursor: pointer;
}

h1.exp {
  font-size: 16px;
}

.expDiv button.btn img {
  width: 18px;
}

.customme button.btn.btnSection.mb-4 {
  background: rgb(40, 146, 215);
  color: #fff;
}
.expDiv {
  margin-bottom: 20px;
}
textarea.textAdd {
  padding: 12px;
  width: 100%;
  min-height: 140px !important;
}

h1.modalText {
  font-size: 16px;
}

.formikLicense {
  color: red;
}
.calenderDiv.modal-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-primary {
  color: #fff;
  background-color: rgb(40, 146, 215);
  border-color: rgb(40, 146, 215);
}
button.btn.btnSection.w-100 {
  background: rgb(40, 146, 215);
  color: #fff;
  border-color: rgb(40, 146, 215);
}
.css-1v5z18m {
  width: 300px;
  margin-left: 12px;
}
.sliderRange {
  display: flex;
}
label.spaace {
  background: rgb(40, 146, 215);
  color: #fff;
  border-radius: 5px;
}

.imgBack {
  align-items: center;
  flex-wrap: wrap;
  background: #f8f9fb;

  padding: 20px 0px;
}
.image-item {
  margin: 28px;
}

button.btn.btnDelete {
  position: absolute;
  font-size: 12px;
  top: 0;
}

.image-item {
  position: relative;
  width: 13%;
}
.imgflex img {
  border-radius: 50%;
  height: 173px;
  width: 200px;
  /* border: 1px solid #c6cbd4; */
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  cursor: pointer;
}

/* ----------------- */
.flexxview {
  display: block;
}

.profile {
  margin: 0 auto;
}

.detaill {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0px;
  text-align: justify;
  justify-content: space-between;
}

.reportview {
  width: 33%;
}

.reportview p {
  width: 150px !important;
  overflow: hidden;
}
.profile img {
  border-radius: 50% !important;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  height: 180px;
  width: 180px;
  margin: 0 auto;
}

.reportview.descrr {
  max-width: 100% !important;
}

.reportview.descrr p {
  width: 100% !important;
  text-align: justify;
}

.reportmainview {
  display: block;
}

.reportcontent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0px;
}

.reportimg img {
  border-radius: 50% !important;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  height: 180px;
  width: 180px;
  margin: 0 auto;
}
.reportimg {
  margin: 0 auto;
}

.css-1cvx1hr {
  display: flex;
  flex-direction: column;
  width: 85%;
  border: 1px solid #cacaca;
  border-radius: 8px;
}
